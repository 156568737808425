/* CustomDateRange.css */

.dark .rdrCalendarWrapper {
    background-color: #101436 !important;
    border: 1px solid #101436;
    color: #e2e8f0;
  }
  
  .dark .rdrDay {
    color: #e2e8f0;
  }
  
  .dark .rdrDayNumber {
    color: #e2e8f0;
  }
  
  .dark .rdrDayNumber span {
    color: #e2e8f0 !important;
  }
  
  .dark .rdrDayNumber span:hover {
    background-color: rgba(2, 246, 209, 0.1) !important;
  }
  
  .dark .rdrDayInPreview {
    background-color: rgba(2, 246, 209, 0.1) !important;
  }
  
  .dark .rdrDayStartPreview,
  .dark .rdrDayEndPreview,
  .dark .rdrDaySelected {
    /* background-color: #02f6d1 !important; */
    /* color: #07091b !important; */
  }

  .dark .rdrDateInput, .dark .rdrInputRangeInput{
    background-color: #101436;
    color: #e2e8f0;
  }
  
  .dark .rdrMonthAndYearPickers select {
    background-color: #101436;
    color: #e2e8f0;
  }
  
  .dark .rdrDefinedRangesWrapper {
    background-color: #101436;
    color: #e2e8f0;
  }
  
  .dark .rdrDefinedRangesWrapper button {
    background-color: #101436;
    color: #e2e8f0;
  }
  
  .dark .rdrDefinedRangesWrapper button:hover {
    /* background-color: #02f6d1; */
    /* color: #07091b; */
  }
  
  .dark .rdrDateDisplayWrapper {
    background-color: #101436;
    border: 1px solid #101436;
  }
  
  .dark .rdrDateDisplayItem {
    color: #e2e8f0;
  }
  